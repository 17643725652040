import { AlertSeverity } from "../interfaces/ui/Enums"
import Configuration from "../interfaces/entities/Configuration"
import Organization from "../interfaces/entities/Organization"
import Unit from "../interfaces/entities/Unit"
import UnitAssessment from "../interfaces/entities/UnitAssessment"
import { UnitAssessmentEvent } from "../interfaces/entities/UnitAssessmentEvent"
import User from "../interfaces/entities/User"
import UserAssessment from "../interfaces/entities/UserAssessment"
import { UserAssessmentEvent } from "../interfaces/entities/UserAssessmentEvent"
import { ISnackbarProps } from "../interfaces/ui/Interfaces"
import ImpactFactorConfiguration from "../interfaces/entities/ImpactFactorConfiguration"
import ImpactFactor from "../interfaces/entities/ImpactFactor"
import { DashboardCheckboxFilter } from "../interfaces/entities/DashboardCheckboxFilter"
import moment from "moment"

export const defaultImpactFactor: ImpactFactor = {
    impactFactorId: "",
    description: "",
    name: "",
    positive: 0,
}

export const defaultImpactFactorConfiguration: ImpactFactorConfiguration = {
    impactFactorConfigurationId: "",
    displayName: "",
    commentVisible: 1,
    impactFactor: defaultImpactFactor,
}

export const defaultConfiguration: Configuration = {
    configurationId: "",
    name: "",
    type: 1,
    active: 1,
    impactFactorConfigurations: [defaultImpactFactorConfiguration],
}

export const defaultUnitAssessment: UnitAssessment = {
    assessmentId: "",
    assessmentMain: 0,
    prognosisLongterm: 0,
    prognosisLongtermComment: "",
    prognosisShortterm: 0,
    prognosisShorttermComment: "",
    submissionDate: "",
    assessmentMax: 0,
    assessmentMin: 0,
    actionsLongterm: "",
    actionsShortterm: "",
    unitImpactAssessmentValues: [],
}

export const defaultUserAssessment: UserAssessment = {
    assessmentId: "",
    assessmentMain: 0,
    prognosisLongterm: 0,
    prognosisLongtermComment: "",
    prognosisShortterm: 0,
    prognosisShorttermComment: "",
    submissionDate: "",
    userImpactAssessmentValues: [],
}

export const defaultUserAssessmentEvent: UserAssessmentEvent = {
    assessmentEventId: "",
    status: 0,
    submittedTimeStamp: "",
    emailStatus: 0,
    plannedDate: "",
    user: {} as User,
    userAssessment: {} as UserAssessment,
}

export const defaultUnitAssessmentEvent: UnitAssessmentEvent = {
    assessmentEventId: "",
    status: 0,
    submittedTimeStamp: "",
    emailStatus: 0,
    plannedDate: "",
    unit: {} as Unit,
    unitAssessment: {} as UnitAssessment,
}

export const defaultUser: User = {
    userId: "",
    email: "",
    firstName: "",
    lastName: "",
    workTitle: "",
    unitSensor: 1,
    active: 1,
    userAssessmentEvents: [defaultUserAssessmentEvent],
    unit: { id: "", name: "" },
}

export const defaultUnit: Unit = {
    unitId: "",
    name: "",
    description: "",
    size: 0,
    active: 1,
    unitAssessmentEvents: [],
    // users: [defaultUser],
    users: [],
    units: [],
    parentUnit: { id: "", name: "" },
}

export const defaultOrganization: Organization = {
    organizationId: "",
    name: "",
    organizationNumber: "",
    description: "",
    num_employees: 0,
    units: [defaultUnit],
    configurations: [],
}

export const defaultSnackbarProps: ISnackbarProps = {
    open: false,
    message: "",
    severity: AlertSeverity.INFO,
}

export const defaultDashboardCheckboxFilter: DashboardCheckboxFilter = {
    Min: false,
    Max: false,
    Main: true,
    PrognosisLong: false,
    PrognosisShort: false,
    ImpactFactor: false,
    TotalSentiment: true,
    Compare: false,
    UnitAssessments: true,
    UserAssessments: false,
    Mode: true,
    Mean: false,
    SelectedDates: { from: moment(moment.now()).subtract(26, "weeks").format("YYYY-MM-DD"), to: moment(moment.now()).format("YYYY-MM-DD") },
    SelectedIds: ["1"],
}

const defaultEntities = {
    defaultOrganization: defaultOrganization,
    defaultUnit: defaultUnit,
    defaultUser: defaultUser,
    defaultUnitAssessmentEvent: defaultUnitAssessmentEvent,
    defaultUnitAssessment: defaultUnitAssessment,
    defaultUserAssessmentEvent: defaultUserAssessmentEvent,
    defaultUserAssessment: defaultUserAssessment,
    defaultSnackbarProps: defaultSnackbarProps,
    defaultConfiguration: defaultConfiguration,
    defaultImpactFactorConfiguration: defaultImpactFactorConfiguration,
    defaultImpactFactor,
    defaultDashboardCheckboxFilter: defaultDashboardCheckboxFilter,
};

export default defaultEntities;
export enum ActionTypes {
    SET_DATALOADED = 'SET_DATALOADED',

    SET_TEXT_ANALYSIS = 'SET_TEXT_ANALYSIS',

    SET_DASHBOARD_FILTER = 'SET_DASHBOARD_FILTER',

    SET_AVAILABLE_LANGUAGES = 'SET_AVAILABLE_LANGUAGES',
    SET_CURRENT_LANGUAGE = 'SET_CURRENT_LANGUAGE',

    SET_AVAILABLE_ORGANIZATIONS = 'SET_AVAILABLE_ORGANIZATIONS',
    SET_ORGANIZATION = 'SET_ORGANIZATION',
    SET_SELECTED_ORGANIZATION_ID = 'SET_SELECTED_ORGANIZATION_ID',

    SET_SELECTED_UNITS = 'SET_SELECTED_UNITS',
    SET_UNITS = 'SET_UNITS',
    ADD_UNIT = 'ADD_UNIT',
    UPDATE_UNITS = 'UPDATE_UNITS',
    DELETE_UNITS = 'DELETE_UNITS',

    SET_SELECTED_USERS = 'SET_SELECTED_USERS',
    SET_USERS = 'SET_USERS',
    ADD_USER = 'ADD_USER',
    UPDATE_USERS = 'UPDATE_USERS',
    DELETE_USERS = 'DELETE_USERS',

    ADDED_UNIT = 'ADDED_UNIT',

    SET_SELECTED_ASSESSMENT = 'SET_SELECTED_ASSESSMENT',
    SET_ASSESSMENTS = 'SET_ASESSMENTS',

    SET_USER_ASSESSMENT_EVENTS = 'SET_USER_ASSESSMENT_EVENTS',
    ADD_USER_ASSESSMENT_EVENTS = 'ADD_USER_ASSESSMENT_EVENTS',
    UPDATE_USER_ASSESSMENT_EVENTS = 'UPDATE_USER_ASSESSMENT_EVENTS',
    DELETE_USER_ASSESSMENT_EVENTS = 'DELETE_USER_ASSESSMENT_EVENTS',

    SET_UNIT_ASSESSMENT_EVENTS = 'SET_UNIT_ASSESSMENT_EVENTS',
    ADD_UNIT_ASSESSMENT_EVENTS = 'ADD_UNIT_ASSESSMENT_EVENTS',
    UPDATE_UNIT_ASSESSMENT_EVENTS = 'UPDATE_UNIT_ASSESSMENT_EVENTS',
    DELETE_UNIT_ASSESSMENT_EVENTS = 'DELETE_UNIT_ASSESSMENT_EVENTS',

    SET_IMPACT_FACTORS = 'SET_IMPACT_FACTORS',
    ADD_IMPACT_FACTORS = 'ADD_IMPACT_FACTORS',
    DELETE_IMPACT_FACTORS = 'DELETE_IMPACT_FACTORS',

    SET_CONFIGURATIONS = 'SET_CONFIGURATIONS',
    ADD_CONFIGURATIONS = 'ADD_CONFIGURATIONS',
    UPDATE_CONFIGURATIONS = 'UPDATE_CONFIGURATIONS',
    DELETE_CONFIGURATIONS = 'DELETE_CONFIGURATIONS',

    SET_VIEW = 'SET_VIEW',

    SET_SHOW_FORM = 'SET_SHOW_FORM',
    SET_SHOW_CONFIRMATION_DIALOG = 'SET_SHOW_CONFIRMATION_DIALOG',
    SET_SHOW_SNACKBAR = 'SET_SHOW_SNACKBAR',

    SET_KEYCLOAK = 'SET_KEYCLOAK',
    SET_EXPORT_SETTINGS = 'SET_EXPORT_SETTINGS',

}
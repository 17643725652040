import { useSelector } from "react-redux";
import Organization from "../interfaces/entities/Organization";
import Unit from "../interfaces/entities/Unit";
import User from "../interfaces/entities/User";
import { ActionTypes } from "../redux/actionTypes";
import { store } from "../redux/storeStates/store";
import { DummyConfigurations, DummyImpactFactors, DummyOrganization, DummySelectedOrganizationId, DummyUnits } from "../utils/DummyData";
import Configuration from "../interfaces/entities/Configuration";
import Routes from "./Routes";
import { useGridColumnResize } from "@material-ui/data-grid";
import ExportSetting from "../interfaces/entities/settings/ExportSettings";

function getUsers(units: Unit[], users: User[]) {
    if (!units) {
        return;
    }
    units.forEach((unit) => {
        unit.users.forEach(user => {
            user.unit = { id: unit.unitId, name: unit.name };
        });
        users.push(...unit.users);
        getUsers(unit.units, users);
    });
}

export function getUnits(nestedUnits: Unit[], units: Unit[]) {
    if (!nestedUnits) {
        return;
    }
    nestedUnits.forEach((nestedUnit) => {
        nestedUnit.units.forEach(unit => {
            unit.parentUnit = { id: nestedUnit.unitId, name: nestedUnit.name };
        });
        units.push(...nestedUnit.units)
        getUnits(nestedUnit.units, units);
    });
}

function getNestedUnits(nestedUnits: Unit[], flatUnits: Unit[]){
    nestedUnits.forEach(unit => {
        flatUnits.push(unit);
        if(unit.units.length > 0){
            getNestedUnits(unit.units, flatUnits);
        }
    })

    return flatUnits;
}

/**
 * DummyData
 */
export function DummyData() {
    let units: Unit[] = [];
    let organization: Organization = DummyOrganization;
    getUnits([{ name: "top", units: DummyUnits }] as Unit[], units);

    units.forEach(unit => {
        unit.users.forEach(user => {
            user.unit.id = unit.unitId;
            user.unit.name = unit.name;
        })
    })

    store.dispatch({
        type: ActionTypes.SET_ORGANIZATION,
        payload: organization
    });

    store.dispatch({
        type: ActionTypes.SET_UNITS,
        payload: units
    });

    store.dispatch({
        type: ActionTypes.SET_CONFIGURATIONS,
        payload: DummyConfigurations
    })

    store.dispatch({
        type: ActionTypes.SET_IMPACT_FACTORS,
        payload: DummyImpactFactors
    })
}

/**
 * ProductionData
 */
export function Data(organizationId: string) {

    // Get settings including language
    Routes.Language.getAll().then(languages => {
        let defaultIndex = 0;
        languages.forEach(language=>{
            Routes.Language.getByName(language.language).then(lang => {
                language.dictionary = lang.dictionary;
                if (lang.defaultLanguage) {
                    defaultIndex = languages.indexOf(language);
                    store.dispatch({ type: ActionTypes.SET_CURRENT_LANGUAGE, payload: languages[defaultIndex] });
                }
            });    
        })
        store.dispatch({ type: ActionTypes.SET_AVAILABLE_LANGUAGES, payload: languages });        
        // store.dispatch({ type: ActionTypes.SET_CURRENT_LANGUAGE, payload: languages[defaultIndex] });
            // Routes.Organization.getById(DummySelectedOrganizationId).then(org => {
        Routes.Organization.getById(organizationId).then(org => {
            Routes.Analysis.getById(org.organizationId).then(analysis => {
                console.log("Analysis", analysis);                
                store.dispatch({ type: ActionTypes.SET_TEXT_ANALYSIS, payload: analysis });
            });
            
            //TODO get settings
            // let exportSetting = {exportType: 'unit'} as ExportSetting;

            let units = org.units;
            let configurations: Configuration[] = org.configurations;
            let newUnits: Unit[] = [];
            let users: User[] = [];

            getUnits([{ name: "top", units: units }] as Unit[], newUnits);
            getUsers(units, users);
            console.log("Users", users);
            store.dispatch({ type: ActionTypes.SET_ORGANIZATION, payload: org });
            store.dispatch({ type: ActionTypes.SET_UNITS, payload: newUnits });
            store.dispatch({ type: ActionTypes.SET_USERS, payload: users });
            store.dispatch({ type: ActionTypes.SET_CONFIGURATIONS, payload: configurations });
            store.dispatch({ type: ActionTypes.SET_DATALOADED, payload: true });
        });
    });

    //Fill store with all available impactFactors
    Routes.ImpactFactor.getAll();
}

export function Login() {

    Routes.Organization.getAll().then(org => {
        store.dispatch({ type: ActionTypes.SET_AVAILABLE_ORGANIZATIONS, payload: org });
        // store.dispatch({ type: ActionTypes.SET_ORGANIZATION, payload: org });
    })
}

const DataInit = {
    // DummyData: DummyData,
    Data: Data,
    Login: Login,
}

export default DataInit;
export enum ExportFields {
    assessmentMain = "assessmentMain",
    assessmentMin = "assessmentMin",
    assessmentMax = "assessmentMax",
    prognosisLongterm = "prognosisLongterm",
    prognosisLongtermComment = "prognosisLongtermComment",
    prognosisShortterm = "prognosisShortterm",
    prognosisShorttermComment = "prognosisShorttermComment",
    actionsLongterm = "actionsLongterm",
    actionsShortterm = "actionsShortterm",
    impactFactors = "impactFactors",
    submissionDate = "submissionDate",
}

export enum Views {
    DASHBOARD = 'dashboard',
    UNIT = 'units',
    USER = 'users',
    SETTINGS = 'settings',
    SCHEDULER = 'schedule',
    CONFIGURATION = 'configuration',
    EXPORT = 'export',
    IMPORT = 'import',
    TEXTANALYSIS = 'textAnalysis',
}

export enum AlertSeverity {
    ERROR = "error",
    WARNING = "warning",
    INFO = "info",
    SUCCESS = "success",
}

export enum FormTypes {
    NEW = "NEW",
    EDIT = "EDIT",
    DATE = "DATE",
    EVENT = "EVENT",
}

export enum SchedulerRepetition {
    SINGLE = "SINGLE",
    WEEKLY = "WEEKLY",
    MONTHLY = "MONTHLY",
}

export enum SchedulerAssessmentType {
    GROUP = "GROUP",
    INDIVIDUAL = "INDIVIDUAL",
}

const ENUMS = {
    Views: Views,
    ExportFields: ExportFields,
    AlertSeverity: AlertSeverity,
    FormTypes: FormTypes,
    SchedulerRepetition: SchedulerRepetition,
    SchedulerAssessmentType: SchedulerAssessmentType,
}

export default ENUMS;
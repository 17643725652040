import { AppBar, Toolbar, Paper, Grid, IconButton } from '@mui/material';
import { createStyles, Theme, ThemeProvider, withStyles, WithStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { Store } from '../redux/storeStates/StoreStates';
import UserForm from './forms/UserForm';
import UnitForm from './forms/UnitForm';
import AssessmentScheduler from './AssessmentScheduler';
import Export from './Export';
import FormEditView from './FormEdit/FormEditView';
import SnackbarAlert from './common/SnackBar';
import Dashboard from './Dashboard';
import Units from './Units';
import Users from './Users';
import { Views } from '../interfaces/ui/Enums';
import ActionsView from './analysis/text/ActionsView';

//Styles will be moved later on
const styles = (theme: Theme) =>
  createStyles({
    paper: {
      // maxWidth: 1936,
      // margin: 'auto',
      overflow: 'hidden',
      height: '100%',
      // backgroundColor: '#232f3e',
    },
    searchBar: {
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    searchInput: {
      fontSize: theme.typography.fontSize,
    },
    block: {
      display: 'block',
    },
    iconButton: {
      marginRight: theme.spacing(1),
    },
    contentWrapper: {
      // margin: '20px',
      padding: '10px',
      height: '100%',
      // backgroundColor: '#232f3e',
    },
  });

export interface ContentProps extends WithStyles<typeof styles> { }

function Content(props: ContentProps) {
  const currentView = useSelector((state: Store) => state.currentView);
  const showForm = useSelector((state: Store) => state.showForm);
  const snackbarAlert = useSelector((state: Store) => state.showSnackBar);

  const { classes } = props;

  return (
    <ThemeProvider theme={props.classes}>
      <Paper className={classes.paper}>
        {/* <AppBar className={classes.searchBar} position="static" color="default" elevation={0}> */}
        {/* <Toolbar>
          </Toolbar> */}
        {/* </AppBar> */}
        <div className={classes.contentWrapper}>

          {/* {() => {
            console.log(123)
            switch (currentView) {
              case Views.DASHBOARD:
                <Dashboard />
                break;
              case Views.UNIT:
                <Units />
                if (showForm.show)
                  <UnitForm FormType={showForm.type} />
                break;
              case Views.USER:
                <Users />
                if (showForm.show)
                  <UserForm FormType={showForm.type} />
                break;
              case Views.SCHEDULER:
                <AssessmentScheduler />
                break;
              case Views.CONFIGURATION:
                <FormEditView />
                break;
              case Views.EXPORT:
                <Export />
                break;

              default:
                return <></>;
            }
          }} */}

          {currentView !== Views.DASHBOARD ? <></> : <Dashboard></Dashboard>}
          {currentView !== Views.UNIT ? <></> : <Units />}
          {currentView !== Views.USER ? <></> : <Users />}
          {/* {currentView !== Views.SETTINGS ? <></> : <FormEditView />} */}
          {currentView !== Views.SCHEDULER ? <></> : <AssessmentScheduler />}
          {currentView !== Views.CONFIGURATION ? <></> : <FormEditView />}
          {currentView !== Views.TEXTANALYSIS ? <></> : <ActionsView />}
          {currentView !== Views.EXPORT ? <></> : <Export />}

          {currentView === Views.UNIT && showForm.show ? <UnitForm FormType={showForm.type} /> : <></>}
          {currentView === Views.USER && showForm.show ? <UserForm FormType={showForm.type} /> : <></>}

          <SnackbarAlert
            open={snackbarAlert.open}
            message={snackbarAlert.message}
            clipboardMessage={snackbarAlert.clipboardMessage}
            severity={snackbarAlert.severity}
          />
        </div>
      </Paper>
    </ThemeProvider>
  );
}

export default withStyles(styles)(Content);